import React from "react"
import axios from "axios"
import User from "models/user"

const initialState = {
  token: null,
  email: null,
  name: null,
  avatarUrl: null,
}

/**
 * List of allowed actions to be applied to the user object
 */
const reducer = (state, action) => {
  let newState = state

  switch (action.type) {
    /**
     * LOAD registers the token
     */
    case "LOAD":
      let storedUser = localStorage.getItem("user_context")

      if (!storedUser) {
        return new User({ ...initialState, isLoading: false })
      }

      storedUser = JSON.parse(storedUser)

      if (storedUser.token) {
        axios.defaults.headers["Authorization"] = "Bearer " + storedUser.token
      }

      newState = {
        ...initialState,
        token: storedUser.token,
        name: storedUser.name,
        avatarUrl: storedUser.avatarUrl,
      }
      break

    /**
     * LOGIN registers the token
     */
    case "LOGIN":
      newState = {
        ...state,
        token: action.token,
        name: action.profile.name,
        avatarUrl: action.profile.avatar_url,
        email: action.profile.email,
      }
      axios.defaults.headers["Authorization"] = "Bearer " + action.token
      break

    /**
     * UPDATE_PROFILE add profile informations
     */
    case "UPDATE_PROFILE":
      newState = {
        ...state,
        name: action.name,
        avatarUrl: action.avatar_url,
        email: action.email,
      }
      break

    /**
     * LOGOUT unregisters the token
     */
    case "LOGOUT":
      localStorage.removeItem("user_context")
      axios.defaults.headers["Authorization"] = null
      newState = initialState
      break

    default:
      throw new Error()
  }

  const user = new User(newState)

  if (typeof window !== "undefined") {
    // we don't want to store email which is too sensitive
    delete newState.email
    localStorage.setItem("user_context", JSON.stringify(newState))
  }

  return user
}

const UserContext = React.createContext()

const UserContextProvider = ({ children }) => {
  const userReducer = React.useReducer(reducer, initialState, () => {
    return new User(initialState)
  })
  return (
    <UserContext.Provider value={userReducer}>{children}</UserContext.Provider>
  )
}

const useUser = () => React.useContext(UserContext)

export { useUser, UserContext, UserContextProvider }
